type DociUser =  {
    _id: { $oid: string },
    userId: string,
    userName: string,
    profileUrl: string,
    userRole?: string,
    email?: string,
    organizationId?: string,
}

const userDociMapperFromServer = (data: any): DociUser => {

    return {
        _id: data['_id'],
        userId: data['user_id'],
        userName: data['user_name'],
        userRole: data['user_role'],
        profileUrl: data['profile_img'],
        email: data['email'],
        organizationId: data['organization_id']
    }
}

const userLeanMapperToServer = (user: DociUser) => {
    return {
        user_id: user.userId,
        user_name: user.userName,
        profile_img: user.profileUrl
    }
}


export type {DociUser}
export {userDociMapperFromServer, userLeanMapperToServer}
