'use client'
import {initializeApp} from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {getAuth} from "firebase/auth";
import {getStorage} from "@firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyA7tw-yr0QZsfYMpBEGWa7Y_cl6VXiCRkM",
    authDomain: "doci-15b71.firebaseapp.com",
    projectId: "doci-15b71",
    storageBucket: "doci-15b71.appspot.com",
    messagingSenderId: "321997908816",
    appId: "1:321997908816:web:a8352eabc2204bc18f1a55",
    measurementId: "G-66SRB4G7NM"
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
