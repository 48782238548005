'use client'
import React, {createContext, useContext, useEffect, ReactNode, useState} from 'react';

import * as RealmWeb from "realm-web";
import {useCurrentUser} from "@/app/services/user/current_user_provider";


const MongoDBContext = createContext<{ app: Realm.App; mongoDB: Realm.Services.MongoDBDatabase | undefined } | undefined>(undefined);

type MongoDBProviderProps = {
    children: React.ReactNode;
};

const app = new RealmWeb.App({id: "application-0-uldss"}); // Move outside if used in multiple places

export const MongoDBProvider = ({ children }: MongoDBProviderProps) => {
    const [mongoDB, setMongoDB] = useState<Realm.Services.MongoDBDatabase | undefined>(undefined);
    const {user, userToken} = useCurrentUser();

    useEffect(() => {
        if(user?.organizationId){
            getMongoDB()
        }
    }, [user?.organizationId]); //TODO: maybe relay on token instead and wrap also current user provider. need to test scenario of nw user and then attach organization, or change organization flow

    const getMongoDB = async () => {
        const token = await userToken()
        const credentials = RealmWeb.Credentials.jwt(token!);
        await app.logIn(credentials);
        const mongodb = app.currentUser?.mongoClient("doci-cluster").db("Global");
        setMongoDB(mongodb)
    };

    return (
        <MongoDBContext.Provider value={{ app, mongoDB }}>
            {children}
        </MongoDBContext.Provider>
    );
};

export const useMongoDB = () => {
    const context = useContext(MongoDBContext);
    if (!context) {
        throw new Error('useMongoDB must be used within a MongoDBProvider');
    }
    return context;
};
