import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        id: null,//TODO:maybe userId - auth model class with empty
        organizationId: null,
    },
    reducers: {
        setUser: (state, action) => {
            state.id = action.payload.userId;
            state.organizationId = action.payload.organizationId;
        },
        clearUser: (state) => {
            state.id = null;
            state.organizationId = null;
        },
    },
});

export const { setUser, clearUser } = userSlice.actions;

export default userSlice.reducer;
