'use client'
import React, {createContext, useContext, useEffect, ReactNode, useState} from 'react';
import {auth} from "@/app/firebase";
import {DociUser, userDociMapperFromServer} from "@/app/services/user/models";
import {GoogleAuthProvider, signInWithPopup, User} from "@firebase/auth";
import * as Realm from "realm-web";
import {updateUser} from "@/app/services/user/user_api";

type UpdateUserProps = {
    profileImg?: string, userName?: string, userRole?: string
}

type CurrentUserContextType = {
    user: DociUser | undefined;
    userToken: () => Promise<string | undefined>;
    logout: () => Promise<void>; // Function to handle auth logout
    login: () => Promise<void>; // Function to handle auth logout
    addOrganization: (organizationId: string) => Promise<Response>; // Function to handle auth logout
    createOrganization: (organizationName: string) => Promise<Response>; // Function to handle auth logout
    updateUser: (props: UpdateUserProps) => Promise<void>
}

const CurrentUserContext = createContext<CurrentUserContextType | undefined>(undefined);

type CurrentUserProviderProps = {
    children: ReactNode;
}
const app = new Realm.App({id: "application-0-uldss"}); // Move outside if used in multiple places

export const CurrentUserProvider = ({children}: CurrentUserProviderProps) => {
    const [user, setUser] = useState<DociUser | undefined>(undefined);

    useEffect(() => {
        let shouldContinue = true; // This flag will control the loop

        const setupChangeStream = async (firebaseUser: User) => {
            const token = await firebaseUser.getIdToken(true)//TODO: Do not use userToken because maybe user is not set yet
            const credentials = Realm.Credentials.jwt(token);
            await app.logIn(credentials);
            const mongodb = app.currentUser?.mongoClient("doci-cluster");
            const collection = mongodb?.db("Global").collection("users");

            const changeStream = collection?.watch([
                {$match: {'fullDocument.user_id': firebaseUser.uid}}
            ]);

            if (changeStream) {
                try {
                    for await (const change of changeStream) {
                        if (!shouldContinue) break; // Break the loop if flagged to stop

                        switch (change.operationType) {
                            case 'insert':
                            case 'update':
                            case 'replace':
                                const updatedData = {
                                    ...change.fullDocument,
                                    _id: {$oid: change.documentKey._id.toString()}
                                };
                                const dociUser: DociUser = userDociMapperFromServer(updatedData);
                                setUser(dociUser);
                                break;
                            case 'delete':
                                setUser(undefined);
                                break;
                            default:
                                console.log(`Unhandled change type: ${change.operationType}`);
                        }
                    }
                } catch (error) {
                    console.error("Error with change stream", error);
                }
            }
        };

        const unsubscribeAuth = auth.onAuthStateChanged(async (firebaseUser) => {
            if (firebaseUser) {
                const user = await serverLogin(firebaseUser);
                if (user) {
                    setUser(user);
                    await setupChangeStream(firebaseUser);
                }
            } else {
                setUser(undefined);
                await serverLogout();
            }
        });

        return () => {
            unsubscribeAuth();
            shouldContinue = false; // This will cause the change stream loop to exit
        };
    }, []);


    const userToken = async () => {
        return auth.currentUser?.getIdToken(true);
    }
    const login = async () => {

        const provider = new GoogleAuthProvider();
        try {
            const userCredential = await signInWithPopup(auth, provider);
            const firebaseUser = userCredential.user
            const user = await serverLogin(firebaseUser)
            if (user) {
                setUser(user)
            }

        } catch (error) {
            console.log(error)
            //TODO: handle error
        }
        return new Promise<void>(resolve => resolve());


    };

    const updateUserHandler = async ({profileImg, userName, userRole}:UpdateUserProps ) => {
        const token = await userToken()
        if (token && user?.userId) {

            const updateUserRes = updateUser({
                token: token,
                userId: user.userId,
                profileImg: profileImg,
                userName,
                userRole
            })
        }
    }

    const logout = async () => {
        await auth.signOut();
    };

    const addOrganization = async (organizationId: string) => {
        const token = await userToken()
        const response = await fetch('/api/user/add_organization', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({token, organizationId})
        });
        return response
    };

    const createOrganization = async (organizationName: string) => { //TODO: not here!
        const token = await userToken()
        const response = await fetch('/api/organization/create', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({token, organizationName})
        });
        return response
    };

    const serverLogin = async (user: User): Promise<DociUser | null> => {
        try {
            const token = await user.getIdToken(true) //TODO: Do not use userToken because maybe user is not set yet
            const userName = user.displayName
            const profileImg = user.photoURL
            const email = user.email

            const response = await fetch('/api/auth/login', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({token, userName, profileImg, email})
            });
            const body = await response.json()
            if (!response.ok) {
                throw new Error(`Failed to log in server-side: ${body.error}`);
            }

            return body;
        } catch (error) {
            console.error("Server login error:", error);
            return null;
        }
    };


    const serverLogout = async () => {
        try {
            const response = await fetch('/api/auth/logout', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
            });
            if (!response.ok) throw new Error('Failed to log out server-side');
        } catch (error) {
            console.error("Server logout error:", error);
        }
    };

    const value = {
        user,
        userToken,
        updateUser: updateUserHandler,
        addOrganization,
        createOrganization,
        logout,
        login
    };

    return (
        <CurrentUserContext.Provider value={value}>
            {children}
        </CurrentUserContext.Provider>
    );
};

// Hook to use the auth context
export const useCurrentUser = () => {
    const context = useContext(CurrentUserContext);
    if (context === undefined) {
        throw new Error('useCurrentUser must be used within a UserProvider');
    }
    return context;
};
